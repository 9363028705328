import React from 'react'
import { useCookies } from 'react-cookie'

import * as S from './cookieConsent.styles'

const CookieConsent = (): JSX.Element | null => {
  const [cookies, setCookie] = useCookies(['jmcarcompany-cookie-consent'])
  const COOKIE_NAME = 'jmcarcompany-cookie-consent'
  const date = new Date(Date.now() + 4 * 24 * 60 * 60 * 1000)

  return !cookies[COOKIE_NAME] ? (
    <S.CookieContainer>
      <S.CookieText>
        Ta strona wykorzystuje pliki cookie. Używamy informacji zapisanych za pomocą plików cookies w celu zapewnienia
        maksymalnej wygody w korzystaniu z naszego serwisu.
      </S.CookieText>
      <S.CookieBtn onClick={() => setCookie(COOKIE_NAME, 'active', { path: '/', expires: date })}>Ok</S.CookieBtn>
    </S.CookieContainer>
  ) : null
}

export default CookieConsent
