import { ComponentClass } from 'react'
import { createGlobalStyle } from 'styled-components'

import reset from './reset'
import fontFaces from 'src/assets/fonts'

const GlobalStyles: ComponentClass = createGlobalStyle`
  ${reset}
  ${fontFaces}

  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-weight: 300;
    font-size: 1.6rem;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5;
  }
`

export default GlobalStyles
