import { css } from 'styled-components'

import montserratRegularWoof from './montserrat-v14-latin-ext_latin-regular.woff'
import montserratRegularWoof2 from './montserrat-v14-latin-ext_latin-regular.woff2'
import montserratSemiboldWoof from './montserrat-v14-latin-ext_latin-600.woff'
import montserratSemiboldWoof2 from './montserrat-v14-latin-ext_latin-600.woff2'
import montserratBoldWoof from './montserrat-v14-latin-ext_latin-700.woff'
import montserratBoldWoof2 from './montserrat-v14-latin-ext_latin-700.woff2'
import montserratBlackWoof from './montserrat-v14-latin-ext_latin-900.woff'
import montserratBlackWoof2 from './montserrat-v14-latin-ext_latin-900.woff2'

const fontFaces = css`
  @font-face {
  font-weight: 400;
  font-family: 'Montserrat';
  font-style: normal;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('${montserratRegularWoof2}') format('woff2'),
       url('${montserratRegularWoof}') format('woff');
  }
  @font-face {
    font-weight: 600;
    font-family: 'Montserrat';
    font-style: normal;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('${montserratSemiboldWoof2}') format('woff2'),
        url('${montserratSemiboldWoof}') format('woff');
  }
  @font-face {
    font-weight: 700;
    font-family: 'Montserrat';
    font-style: normal;
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('${montserratBoldWoof2}') format('woff2'),
        url('${montserratBoldWoof}') format('woff');
  }
  @font-face {
    font-weight: 900;
    font-family: 'Montserrat';
    font-style: normal;
    src: local('Montserrat Black'), local('Montserrat-Black'),
        url('${montserratBlackWoof2}') format('woff2'),
        url('${montserratBlackWoof}') format('woff');
  }
`

export default fontFaces
