import React from 'react'

import * as S from './button.styles'

interface ButtonProps {
  path: string
  varaint?: 'arrow'
  color?: 'white'
  direction?: 'down' | 'right'
}

const Button: React.FC<ButtonProps> = props => {
  const { path, varaint, color, direction, children } = props

  return (
    <S.Button to={path} varaint={varaint} color={color}>
      {children}
      {varaint && <S.Arrow color={color} direction={direction} />}
    </S.Button>
  )
}

export default Button
