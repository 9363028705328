import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import ArrowComponent from 'src/assets/images/vectors/arrow.svg'

interface ButtonProps {
  varaint?: 'arrow'
  color?: 'white'
}

interface ArrowProps {
  color?: 'white'
  direction?: 'down' | 'right'
}

export const Button = styled(Link)<ButtonProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 3.7rem;
  color: ${({ theme }) => theme.red};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  text-decoration: none;
  border: 0.2rem solid ${({ theme }) => theme.red};

  ${({ color, theme }) =>
    color === 'white' &&
    css`
      background: ${theme.white};
      border: 0.2rem solid ${theme.white};
    `}

  ${({ varaint }) =>
    varaint === 'arrow' &&
    css`
      padding: 0.8rem 3.7rem;
    `}
`

export const Arrow = styled(ArrowComponent)<ArrowProps>`
  width: 3.2rem;
  margin-left: 2rem;

  ${({ direction }) =>
    direction === 'right' &&
    css`
      transform: rotate(-90deg);
    `}
`
