import { DefaultTheme } from 'styled-components'

import { Colors, Breakpoints } from 'src/types/styled'

const breakpoints: Breakpoints = {
  huge: '1700px',
  bigDesktop: '1440px',
  desktop: '1150px',
  bigTablet: '1020px',
  tablet: '768px',
  phone: '374px',
}

const colors: Colors = {
  red: 'rgb(202, 33, 40)',
  transparentRed: 'rgb(202, 33, 40, 0.8)',
  gray: 'rgb(158, 160, 163)',
  darkgray: 'rgb(55, 56, 60)',
  lightgray: 'rgb(229, 229, 229)',
  white: 'rgb(255, 255, 255)',
  boxShadow: 'rgb(114,114,114,0.16)',
  heroHomeBackground: 'rgba(0,0,0,0.5)',
}

export const theme: DefaultTheme = {
  ...colors,
  font: {
    weight: {
      regular: '400',
      semibold: '600',
      bold: '700',
      black: '900',
    },
    size: {
      headers: {
        xs: '1.2rem',
        s: '1.6rem',
        m: '2.4rem',
        l: '3.2rem',
        xl: '3.9rem',
        xxl: '6rem',
      },
      body: {
        s: '1.6rem',
        m: '2.4rem',
        l: '3.2rem',
        xl: '6rem',
        xxl: '10rem',
      },
    },
  },
  mq: Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]})`
    return acc
  }, {} as Record<keyof Breakpoints, string>),
  zIndex: {
    level1: 1000,
    level2: 2000,
    level3: 3000,
    level4: 4000,
    level10: 10000,
  },
}
