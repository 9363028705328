import styled from 'styled-components'

export const CookieContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  z-index: 1000;
  max-width: 400px;
  margin-left: 2rem;
  padding: 3rem;
  background: ${({ theme }) => theme.white};
  border-radius: 3rem;
  box-shadow: 0 4px 24px ${({ theme }) => theme.boxShadow};
  opacity: 0;
  animation-name: slideInFromBottom;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-delay: 2s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(5%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

export const CookieText = styled.p`
  font-weight: ${({ theme }) => theme.font.weight.regular};
`

export const CookieBtn = styled.button`
  display: block;
  margin-top: 1rem;
  margin-left: auto;
  padding: 3px;
  color: $primaryGreen;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: 2rem;
  text-transform: uppercase;
  background: transparent;
  border: none;
  cursor: pointer;
`
